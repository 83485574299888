/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@import "~swiper/css";
@import "~swiper/css/autoplay";
@import "~swiper/css/pagination";

@font-face {
  font-family: 'ComicNeue';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/Comic_Neue/ComicNeue-Regular.ttf');
}

@font-face {
  font-family: 'ComicNeue';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/Comic_Neue/ComicNeue-Bold.ttf');
}

ion-content.gradient {
  --background: linear-gradient(180deg, #f7be07 0%, #fff 10%) !important
}


swiper span.swiper-pagination-bullet-active {
  width: 70px !important;
  border-radius: 10px !important;
  background: var(--ion-color-tertiary,#000);
}

swiper span.swiper-pagination-bullet {
  transition: .5s;
}

.primario{
  color: var(--ion-color-primary,#e28330);
}

.secondario{
  color: var(--ion-color-secondary,#1d24ab);
}

.terziario{
  color: var(--ion-color-tertiary,#f7be07);
}

ion-title.md {
  background: url('/assets/img/logo_title.png') center center;
  background-size: contain;
  background-repeat: no-repeat;
  max-height: 41px;
  margin-top: 3px;
  height: 41px;
  margin-left: 16px;
}

ion-title.ios {
  background: url('/assets/img/logo_title.png') center center;
  background-size: contain;
  background-repeat: no-repeat;
  max-height: 41px;
  margin-top: 3px;
}